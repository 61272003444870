<template>
  <div class="wameed-dashboard-page-content settings-page">
    <page-header
        :title="$t('settings.title')"
    />

    <section class="wameed-dashboard-page-content_body">

      <div class="settings-page--grid">

        <settings-card
            @onClick="companyDetails"
            icon="company-icon"
            :title="$t('settings.company_data')"
        />
        <settings-card
            @onClick="workDays"
            icon="date-icon"
            :title="$t('settings.work_days')"
        />
      </div>

    </section>
  </div>
</template>

<script>

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import SettingsCard from "@/views/pages/settings/components/settingsCard.vue";

export default {
  components: {
    SettingsCard,
    PageHeader
  },
  data() {
    return {};
  },

  methods: {
    companyDetails() {
      this.$router.push({
        name: 'profile',
        params: {lang: this.$i18n.locale},
      });
    },


    workDays() {
      this.$router.push({
        name: 'work_days',
      });
    },
  },
};
</script>

